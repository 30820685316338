import { createContext, useReducer } from "react";
import getAssetStyleInfo from "./AssetStyleInfo";
import { toolReducer } from "../reducers/toolReducer";
import * as tab from "../constants/tabs";

const toolbar = [
  {
    name: "Select",
    items: [
      getAssetStyleInfo("select"),
      getAssetStyleInfo("select-group"),
      getAssetStyleInfo("re-shape"),
      getAssetStyleInfo("select-results"),
    ],
  },
  {
    name: "Delete",
    items: [getAssetStyleInfo("delete"), getAssetStyleInfo("delete-group")],
  },
  {
    name: "Substation",
    items: [getAssetStyleInfo("substation-gmt"), getAssetStyleInfo("substation-pmt")],
  },
  {
    name: "Cable",
    items: [
      getAssetStyleInfo("underground-cable"),
      getAssetStyleInfo("overhead-line"),
      getAssetStyleInfo("node"),
      getAssetStyleInfo("editCable"),
    ],
  },
  {
    name: "Customer",
    items: [
      getAssetStyleInfo("domestic"),
      getAssetStyleInfo("small-commercial"),
      getAssetStyleInfo("large-commercial"),
      getAssetStyleInfo("industrial"),
      getAssetStyleInfo("generator"),
      getAssetStyleInfo("welder"),
      getAssetStyleInfo("motor"),
      getAssetStyleInfo("evChargePoint"),
      getAssetStyleInfo("heatPump"),
      getAssetStyleInfo("point-of-connection"),
    ],
  },
];

const modes = [
  {
    name: "Select",
    items: [
      {
        name: "Select",
        class: "select",
      },
      {
        name: "Select Group",
        class: "select",
      },
    ],
  },
  {
    name: "Delete",
    items: [
      {
        name: "Delete",
        class: "delete",
      },
      {
        name: "Delete Group",
        class: "delete",
      },
    ],
  },
];

const initialState = {
  mode: "select",
  modes: modes,
  selectedMode: modes.map((t) => 0),
  toolbar: toolbar,
  selectedTool: toolbar.map((t) => 0),
  tool: "",
  activeCategory: 0,
  activeTool: "",
  selectedObj: {},
  sidebar: true,
  clickedAsset: null,
  showResults: false,
  alert: {},
  warnings: {},
  drawMarker: false,
  drawCable: false,
  drawBoundary: false,
  currentLatLng: null,
  mountEvent: null,
  assessmentRunning: false,
  assessmentFailed: false,
  costingFailed: false,
  lines: false,
  geometry: false,
  linesLoaded: false,
  geometryLoaded: false,
  activeTab: tab.FILE,
  feedbackForm: false,
  isLoading: false,
  ringfenced: [],
  ringfencedFiltered: [],
  ringfencedTypes: [],
  disableBoundary: false,
  feederList: null,
  filteredFeeders: {},
  searchValue: "",
  errors: {},
  ringfenceUpdated: false,
  leafletMap: null,
  showNearbyAssets: {
    disabled: false,
    substations: true,
  },
  showMainsOnly: {
    disabled: false,
    mains: true,
  },
  mainsOnly: false,
  electricOfficeOnly: true,
  navigateToLatLng: null,
  selectedAssetId: null,
  consumerCountChangedId: "",
  useAntData: true,
  originalSitePlans: [],
  sitePlans: [],
  selectedSitePlanId: undefined,
  updateSitePlan: 0,
  resetSitePlan: 0,
  refreshSitePlan: false,
  clickedFaultDetectionCable: null,
  matchedSearchIds: [],
  keyDown: {},
  groupClick: 0,
  skipAdmd: false,
  showFeederList: false,
};

export const ToolContext = createContext();
const ToolContextProvider = ({ children }) => {
  const [toolState, setToolState] = useReducer(toolReducer, initialState);
  return (
    <ToolContext.Provider value={{ toolState, setToolState }}>{children}</ToolContext.Provider>
  );
};

export default ToolContextProvider;
