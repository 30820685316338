import React, { useContext } from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

import { FormContext } from "../context/FormContext";
import { ToolContext } from "../context/ToolContext";

import getAssetStyleInfo from "../context/AssetStyleInfo";

import { refreshPolesForCable, getPoleTermLengthForTransformer } from "../utils/poleFunctions";
import { getTransformerPhase } from "../utils/networkFunctions";
import { clearResults, findAll, updateCable, updateTransformer } from "../app/networkSlice";
import { getResultProperties } from "../utils/referenceFunctions";
import { store } from "../app/store";
import { endBatchAction, startBatchAction } from "../app/undoable";

const options = {
  transformer: {
    items: [
      {
        name: "Ground-mounted Transformer",
        class: "substation-gmt",
        type: "transformer",
      },
      {
        name: "Pole-mounted Transformer",
        class: "substation-pmt",
        type: "transformer",
      },
    ],
  },
  cable: {
    items: [
      { name: "Underground Cable", class: "underground-cable", type: "cable" },
      { name: "Overhead Line", class: "overhead-line", type: "cable" },
    ],
  },
};

const AssetToggler = ({ activeTool, textColor }) => {
  const { formState, dispatch } = useContext(FormContext);
  const { toolState, setToolState } = useContext(ToolContext);
  const { clickedAsset } = toolState;
  const { clientSettings, reference } = formState;

  const dispatchRedux = useDispatch();

  const allAssets = useSelector((state) => findAll(state));
  const transformers = useSelector((state) => state.network.present.transformers);
  const cables = useSelector((state) => state.network.present.cables);

  const selectAsset = (asset) => {
    store.dispatch(startBatchAction());

    try {
      if (asset.type === "transformer") {
        const mounting = asset.class.split("-").pop();
        const activeTool = getAssetStyleInfo(asset.class);

        const _toolState = toolState;
        _toolState.activeTool = activeTool;
        _toolState.showResults = false;
        setToolState(_toolState);
        dispatchRedux(clearResults(getResultProperties(clientSettings)));

        const poleTermLength = getPoleTermLengthForTransformer(
          clientSettings,
          clickedAsset.id,
          cables,
          reference,
        );

        const transformerDispatches = [
          { form: "transformers", field: "styles", value: activeTool },
          {
            form: "transformers",
            field: "nameplateRating",
            value: reference.transformers.defaults[mounting],
          },
          {
            form: "transformers",
            field: "mounting",
            value: mounting,
          },
          {
            form: "transformers",
            field: "fuseRating",
            value: reference.transformers.defaults["fuseSize"],
          },
          {
            form: "transformers",
            field: "poleTermLength",
            value: poleTermLength,
          },
        ];

        let _clickedAsset = { ...clickedAsset };
        _clickedAsset.nameplateRating = reference.transformers.defaults[mounting];
        _clickedAsset.mounting = mounting;
        _clickedAsset.fuseRating = reference.transformers.defaults["fuseSize"];
        _clickedAsset.poleTermLength = poleTermLength;

        dispatch({
          form: "transformerProperties",
          obj: _clickedAsset,
          type: "REPLACE_STATE",
        });

        transformerDispatches.forEach((item) => {
          dispatchRedux(
            updateTransformer({ id: _clickedAsset.id, name: item.field, value: item.value }),
          );
        });
      }
      if (asset.type === "cable") {
        const cable = `${clickedAsset.cableGroup.split("-")[0]}-${asset.class.split("-")[0]}`;
        const activeTool = getAssetStyleInfo(asset.class);

        const _toolState = toolState;
        _toolState.activeTool = activeTool;
        setToolState(_toolState);

        const defaultCableNumberOfPhases = getTransformerPhase(formState, transformers);

        const cableDispatches = [
          { form: "cables", field: "styles", value: activeTool },
          { form: "cables", field: "cableGroup", value: cable },
          {
            form: "cables",
            field: "cableType",
            value: reference.cables.defaults[cable],
          },
          {
            form: "cables",
            field: "numberOfPhases",
            value: defaultCableNumberOfPhases,
          },
        ];

        let _clickedAsset = { ...clickedAsset };
        _clickedAsset.cableGroup = cable;
        _clickedAsset.cableType = reference.cables.defaults[cable];
        _clickedAsset.numberOfPhases = defaultCableNumberOfPhases;

        dispatch({
          form: "cableProperties",
          obj: _clickedAsset,
          type: "REPLACE_STATE",
        });

        cableDispatches.forEach((item) => {
          dispatchRedux(updateCable({ id: _clickedAsset.id, name: item.field, value: item.value }));
        });
        dispatchRedux(clearResults(getResultProperties(clientSettings)));

        refreshPolesForCable(
          clientSettings,
          [clickedAsset.startAssetId, clickedAsset.endAssetId],
          cables,
          allAssets,
          dispatchRedux,
          reference,
          cable,
          clickedAsset.id,
        );
      }
    } finally {
      store.dispatch(endBatchAction());
    }
  };

  return (
    <UncontrolledDropdown>
      <DropdownToggle
        tag="div"
        className={`d-flex justify-content-between text-${textColor}-alpha p-0 mb-1 w-100`}
        style={{
          fontSize: ".75rem",
          textTransform: "uppercase",
          cursor: "pointer",
        }}
      >
        {activeTool.name}
        <span>
          <i className={`icon-${activeTool.class}`}></i> <FontAwesomeIcon icon={faCaretDown} />
        </span>
      </DropdownToggle>
      <DropdownMenu className="w-100">
        {options[activeTool.type].items.map((option) => (
          <DropdownItem key={option.name} onClick={() => selectAsset(option)}>
            {option.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default AssetToggler;
