import { useContext } from "react";
import L from "leaflet";
import along from "@turf/along";
import length from "@turf/length";
import { lineString } from "@turf/helpers";
import ReactDOMServer from "react-dom/server";
import { Marker, useMap } from "react-leaflet";

import { ToolContext } from "../context/ToolContext";
import { FormContext } from "../context/FormContext";
import { parseResults } from "../utils/parseResults";

import "./Asset.css";
import { useSelector } from "react-redux";

const ResultMarker = ({ asset, onClick, isDragging }) => {
  const { toolState } = useContext(ToolContext);
  const { formState } = useContext(FormContext);

  const cables = useSelector((state) => state.network.present.cables);
  const hasClearedResults = useSelector((state) => state.network.present.hasClearedResults);

  const { clientSettings, groupedConnectionProperties } = formState;
  const { clickedAsset } = toolState;

  const iconSize = useSelector((state) => state.settings.iconSize);

  let results = parseResults(
    asset,
    clientSettings,
    cables,
    clickedAsset,
    groupedConnectionProperties,
    hasClearedResults,
  );

  const map = useMap();

  const resultMarker = (result) => {
    const icon = L.divIcon({
      className: "resultMarker",
      html: ReactDOMServer.renderToString(
        <div className={`badge bg-dark text-${result.class} p-0`}>
          {result.value && (
            <span
              id={asset.id + result.category}
              className={result.value}
              style={{
                display: "inline-block",
                fontSize: ".875rem",
                padding: ".125rem .25rem",
              }}
            >
              {result.value}
            </span>
          )}
          {result.unit && (
            <span
              style={{
                fontWeight: 300,
                fontSize: ".625rem",
                paddingRight: ".25rem",
              }}
            >
              {result.unit}
            </span>
          )}
          {result.constraint && (
            <span
              className="bg-danger text-white"
              style={{
                display: "inline-block",
                fontSize: ".875rem",
                padding: ".125rem .25rem",
              }}
            >
              !
            </span>
          )}
        </div>,
      ),
    });
    return icon;
  };

  const position = (i, arr) => {
    const _iconSize = asset.styles.name === "Node" ? 12 : iconSize * 12;
    let geometry;
    if (Array.isArray(asset.geometry)) {
      const line = lineString(asset.geometry.map((a) => [a.lat, a.lng]));
      const options = { units: "kilometers" };
      const lineLength = length(line, options);
      const halfwayPoint = along(line, lineLength / 2, options);
      geometry = halfwayPoint.geometry.coordinates;
    } else {
      geometry = asset.geometry;
    }

    const point = map.latLngToContainerPoint(geometry);

    const newPoint = L.point([
      point.x + (asset.styles.tool === "Marker" ? _iconSize : 0),
      point.y + (32 * i - 32 * (i / 2) - (32 / 4) * arr.length),
    ]);

    return map.containerPointToLatLng(newPoint);
  };

  const zoomFilterResults = (result) => {
    const filterInfo = clientSettings.filterResults
      .flatMap((r) => r.info)
      .find((i) => i.value === result.category);
    const mapZoom = map.getZoom();
    return filterInfo && mapZoom >= filterInfo.minZoom && mapZoom <= filterInfo.maxZoom;
  };

  return (
    <>
      {!clientSettings.Features.AssessmentResultsPopupEnabled &&
        !isDragging &&
        results
          .filter((f) => zoomFilterResults(f) && !f.hide)
          .map((result, i, arr) => (
            <Marker
              key={i}
              position={position(i, arr)}
              icon={resultMarker(result)}
              eventHandlers={{
                click: () => {
                  onClick(asset);
                },
              }}
            />
          ))}
    </>
  );
};

export default ResultMarker;
