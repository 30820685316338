const styles = [
  {
    name: "Select",
    class: "select",
    type: "select",
    id: "Select",
  },
  {
    name: "Select Group",
    class: "select-group",
    type: "select",
    id: "SelectGroup",
  },
  {
    name: "Re-shape",
    class: "re-shape",
    type: "select",
    id: "Reshape",
    requiredFeature: "EditModeEnabled",
  },
  {
    name: "Select Results",
    class: "select-results",
    type: "select",
    id: "SelectResults",
    requiredFeature: "AssessmentResultsPopupEnabled",
  },
  {
    name: "Split",
    class: "editCable",
    type: "select",
    id: "SplitCable",
  },
  {
    name: "Delete",
    class: "delete",
    type: "delete",
    id: "Delete",
  },
  {
    name: "Delete Group",
    class: "delete-group",
    type: "delete",
    id: "DeleteGroup",
  },
  {
    name: "Ground-mounted Transformer",
    class: "substation-gmt",
    type: "transformer",
    tool: "Marker",
    circleMarker: true,
    borderColor: "transparent",
    size: 3,
    id: "GroundMountedTransformer",
  },
  {
    name: "Pole-mounted Transformer",
    class: "substation-pmt",
    type: "transformer",
    tool: "Marker",
    circleMarker: true,
    borderColor: "transparent",
    size: 3,
    id: "PoleMountedTransformer",
  },
  {
    name: "Underground Cable",
    class: "underground-cable",
    type: "cable",
    tool: "Line",
    weight: "3",
    color: "rgb(52, 58, 64)",
    id: "UndergroundCable",
  },
  {
    name: "Overhead Line",
    class: "overhead-line",
    type: "cable",
    tool: "Line",
    weight: "6",
    color: "rgb(52, 58, 64)",
    id: "OverheadLine",
  },
  {
    name: "Node",
    class: "node",
    type: "groupedConnection",
    tool: "Marker",
    circleMarker: false,
    borderColor: "transparent",
    size: 3,
    id: "Node",
  },
  {
    name: "Domestic Property",
    class: "domestic",
    type: "connectionPoint",
    tool: "Marker",
    circleMarker: true,
    borderColor: "transparent",
    size: 3,
    id: "DomesticProperty",
  },
  {
    name: "Small Commercial Property",
    class: "small-commercial",
    type: "connectionPoint",
    tool: "Marker",
    circleMarker: true,
    borderColor: "transparent",
    size: 3,
    id: "SmallCommercialProperty",
  },
  {
    name: "Large Commercial Property",
    class: "large-commercial",
    type: "connectionPoint",
    tool: "Marker",
    circleMarker: true,
    borderColor: "transparent",
    size: 3,
    id: "LargeCommercialProperty",
  },
  {
    name: "Industrial Property",
    class: "industrial",
    type: "connectionPoint",
    tool: "Marker",
    circleMarker: true,
    borderColor: "transparent",
    size: 3,
    id: "IndustrialProperty",
  },
  {
    name: "Generator",
    class: "generator",
    type: "connectionPoint",
    tool: "Marker",
    circleMarker: true,
    borderColor: "transparent",
    size: 3,
    id: "Generator",
  },
  {
    name: "Welder",
    class: "welder",
    type: "connectionPoint",
    tool: "Marker",
    circleMarker: true,
    borderColor: "transparent",
    size: 3,
    id: "Welder",
  },
  {
    name: "Motor",
    class: "motor",
    type: "connectionPoint",
    tool: "Marker",
    circleMarker: true,
    borderColor: "transparent",
    size: 3,
    id: "Motor",
  },
  {
    name: "EV Chargepoint",
    class: "evChargePoint",
    type: "connectionPoint",
    tool: "Marker",
    circleMarker: true,
    borderColor: "transparent",
    size: 3,
    id: "EvChargePoint",
  },
  {
    name: "Grouped Connection",
    class: "connection",
    type: "groupedConnection",
    tool: "Marker",
    circleMarker: true,
    borderColor: "transparent",
    size: 3,
    id: "GroupedConnection",
  },
  {
    name: "Heat Pump",
    class: "heatPump",
    type: "connectionPoint",
    tool: "Marker",
    circleMarker: true,
    borderColor: "transparent",
    size: 3,
    id: "HeatPump",
  },
  {
    name: "Map Marker",
    class: "map-marker",
    type: "mapMarker",
    tool: "MapMarker",
    circleMarker: false,
    borderColor: "transparent",
    size: 4,
    id: "MapMarker",
  },
  {
    name: "Point of Connection",
    class: "point-of-connection",
    type: "connectionPoint",
    tool: "Marker",
    circleMarker: true,
    borderColor: "transparent",
    size: 3,
    id: "PointOfConnection",
    requiredFeature: "PointOfConnectionEnabled",
  },
];

export default function getAssetStyleInfo(className) {
  return styles.find((s) => s.class === className);
}
