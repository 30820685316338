import React, { useState, useContext } from "react";
import { ListGroup, ListGroupItem, Collapse } from "reactstrap";

import FaultFilterSubGroup from "./FaultFilterSubGroup";

import { ToolContext } from "../context/ToolContext";
import { FormContext } from "../context/FormContext";
import IconRadioGrp from "./IconRadioGrp";
import { Identifiers } from "./Identifiers";

const FaultFilterGroup = ({ faults, group, categories, onCheckboxBtnClick, defaultSeason }) => {
  const { toolState } = useContext(ToolContext);
  const { formState } = useContext(FormContext);
  const { filterResults } = { ...formState.clientSettings };
  const [collapse, setCollapse] = useState(false);

  const checkForIdentifiers = (result) => {
    const identifiers =
      result.name === "Labels" || result.name === "Object IDs" || result.name === "Feeder ID";
    return identifiers;
  };

  const setValue = (e, result) => {
    Object.entries(e).forEach(([key, value]) => (result[key] = value));
    onCheckboxBtnClick(result);
  };

  const getFaults = (result) => {
    return faults.find(
      (f) => f.value === (result.season ? result.season + result.value : result.value),
    )?.constraintCount;
  };

  const update = (result) => {
    if (defaultSeason && result.season) {
      setValue({ isSet: !result.isSet, season: defaultSeason }, result);
    } else {
      setValue({ isSet: !result.isSet }, result);
    }
  };

  return (
    <ListGroup flush>
      <>
        {filterResults && (
          <>
            <ListGroupItem className="bg-dark" style={{ textTransform: "uppercase" }}>
              {group}
            </ListGroupItem>
            <>
              {categories.map((result) => (
                <React.Fragment key={result.id}>
                  {result.options ? (
                    <FaultFilterSubGroup
                      result={result}
                      faults={faults}
                      filterResults={filterResults}
                      setValue={(e) => setValue(e, result)}
                      defaultSeason={defaultSeason}
                    />
                  ) : checkForIdentifiers(result) ? (
                    result.name === "Labels" ? (
                      <>
                        <ListGroupItem
                          tag="a"
                          action
                          className="d-flex justify-content-between text-white"
                          onClick={() => setCollapse(!collapse)}
                        >
                          <span>Identifier</span>
                          <span className="list-group-item-icon">
                            <i
                              className={`icon-${collapse ? "chevron-down" : "chevron-right"}`}
                            ></i>
                          </span>
                        </ListGroupItem>
                        <Collapse isOpen={collapse}>
                          <Identifiers
                            result={result}
                            faults={faults}
                            getFaults={() => getFaults(result)}
                            setValue={(e) => setValue(e, result)}
                          />
                        </Collapse>
                      </>
                    ) : (
                      <Collapse isOpen={collapse}>
                        <Identifiers
                          result={result}
                          faults={faults}
                          getFaults={() => getFaults(result)}
                          setValue={(e) => setValue(e, result)}
                        />
                      </Collapse>
                    )
                  ) : (
                    <ListGroupItem
                      tag="a"
                      action
                      className={`d-flex justify-content-between text-${
                        result.isSet ? result.color : "black"
                      }`}
                      onClick={() => update(result)}
                    >
                      <span className="mr-4">
                        {result.name}
                        {faults.length > 0 && toolState.showResults && getFaults(result) > 0 && (
                          <span className="badge badge-danger ml-1">{getFaults(result)}</span>
                        )}
                      </span>
                      <span>
                        {result.isSet && result.season && (
                          <IconRadioGrp
                            array={[
                              { name: "summer", color: "warning", icon: "sun" },
                              {
                                name: "winter",
                                color: "info",
                                icon: "snowflake",
                              },
                              { name: "All", color: "light", icon: "all" },
                            ]}
                            value={result.season}
                            setValue={(e) => setValue(e, result)}
                          />
                        )}
                        <span className="list-group-item-icon">
                          <i className={`icon-${result.isSet ? "check" : "cross"}-solid`}></i>
                        </span>
                      </span>
                    </ListGroupItem>
                  )}
                </React.Fragment>
              ))}
            </>
          </>
        )}
      </>
    </ListGroup>
  );
};
export default FaultFilterGroup;
