import { LatLng } from "leaflet";

export const convertFeatures = (features) => {
  const convertedFeatures = [];
  features.forEach((feature) => {
    const coords = feature.geometry ? feature.geometry.coordinates : null,
      geometry = feature.geometry,
      _coordsToLatLng = coordsToLatLng;
    let latlng, latlngs;

    if (!coords && !geometry) {
      return null;
    }

    switch (geometry.type) {
      case "Point":
        latlng = _coordsToLatLng(coords);
        feature.geometry.coordinates = latlng;
        return convertedFeatures.push(feature);

      case "LineString":
      case "MultiLineString":
        latlngs = coordsToLatLngs(coords, geometry.type === "LineString" ? 0 : 1, _coordsToLatLng);
        feature.geometry.coordinates = latlngs;
        return convertedFeatures.push(feature);

      case "Polygon":
      case "MultiPolygon":
        latlngs = coordsToLatLngs(coords, geometry.type === "Polygon" ? 1 : 2, _coordsToLatLng);
        feature.geometry.coordinates = latlngs;
        return convertedFeatures.push(feature);

      default:
        throw new Error("Invalid GeoJSON object.");
    }
  });
  return convertedFeatures;
};

function coordsToLatLng(coords) {
  return new LatLng(coords[1], coords[0], coords[2]);
}

function coordsToLatLngs(coords, levelsDeep, _coordsToLatLng) {
  const latlngs = [];

  for (let i = 0, len = coords.length, latlng; i < len; i++) {
    latlng = levelsDeep
      ? coordsToLatLngs(coords[i], levelsDeep - 1, _coordsToLatLng)
      : (_coordsToLatLng || coordsToLatLng)(coords[i]);

    latlngs.push(latlng);
  }

  return latlngs;
}
