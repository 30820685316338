import axios, { get, patch, post } from "axios";

const deleteAction = axios.delete;

const { REACT_APP_B2B, REACT_APP_B2C, REACT_APP_BA_LOGIN_URL } = process.env;

const b2x = REACT_APP_B2B === "true" || REACT_APP_B2C === "true";
const clientSettingsApiUrl = process.env.REACT_APP_CLIENT_SETTINGS_API_URL;

let clientSettings;

const getBounds = (bounds) => {
  return {
    southLat: bounds.getSouth(),
    westLng: bounds.getWest(),
    northLat: bounds.getNorth(),
    eastLng: bounds.getEast(),
  };
};

const postHeaders = (bearerToken) => {
  let headers = {};

  headers.headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  if (b2x) {
    headers.headers = {
      ...headers.headers,
      Authorization: `Bearer ${bearerToken}`,
    };
  }

  return headers;
};

const authenticateUser = (user, password) => {
  let headers = {};

  headers.headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const token = `${user}:${password}`;
  const hash = window.btoa(token);

  headers.headers = {
    ...headers.headers,
    Authorization: `Basic ${hash}`,
  };

  return headers;
};

const getHeaders = (bearerToken) => {
  let headers = {};

  if (b2x) {
    headers.headers = {
      Authorization: `Bearer ${bearerToken}`,
    };
  }

  return headers;
};

export const authorise2 = async (user, password) => {
  const headers = authenticateUser(user, password);
  return post(REACT_APP_BA_LOGIN_URL, {}, headers);
};

export const authorise = async (token) => {
  const headers = postHeaders(token);
  return post(`${clientSettings?.Urls.EndPoints.Authorised}`, {}, headers);
};

export const getReference = async (token) => {
  const headers = getHeaders(token);
  return get(`${clientSettings?.Urls.EndPoints.Reference}`, headers)
    .then((result) => result.data)
    .catch((error) => {
      console.error(error.response.data.error);
    });
};

export const getDefaultReference = async (token) => {
  const headers = getHeaders(token);
  return get(`${clientSettings?.Urls.EndPoints.DefaultReference}`, headers)
    .then((result) => result.data)
    .catch((error) => {
      console.error(error.response.data.error);
    });
};

export const getSpecificReference = async (token, client, version) => {
  const headers = getHeaders(token);
  let url = clientSettings.Urls.EndPoints.SpecificReference;
  url = url.replace("{client}", encodeURIComponent(client));
  url = url.replace("{version}", encodeURIComponent(version));
  return get(url, headers)
    .then((result) => result.data)
    .catch((error) => {
      console.error(error.response.data.error);
    });
};

export const viewConfig = async (token, client, version) => {
  const headers = getHeaders(token);
  let url = clientSettings.Urls.EndPoints.ViewSpecificConfig;
  url = url.replace("{client}", encodeURIComponent(client));
  url = url.replace("{version}", encodeURIComponent(version));

  return get(url, headers)
    .then((result) => result.data)
    .catch((error) => {
      console.error(error.response.data.error);
    });
};

export const listConfigs = async (token) => {
  const headers = getHeaders(token);
  return get(`${clientSettings?.Urls.EndPoints.ListConfigs}`, headers)
    .then((result) => result.data)
    .catch((error) => {
      console.error(error.response.data.error);
    });
};

export const translateConfigToUI = async (token, raw) => {
  const options = await getHeaders(token);
  return post(
    `${clientSettings.Urls.EndPoints.TranslateRawConfigToUI}`,
    { files: [{ contents: raw }] },
    options,
  )
    .then((result) => result.data)
    .catch((error) => {
      console.error(error.response.data.error);
    });
};

export const translateConfigToView = async (token, raw) => {
  const options = await getHeaders(token);
  return post(
    `${clientSettings.Urls.EndPoints.TranslateRawConfigToView}`,
    { files: [{ contents: raw }] },
    options,
  )
    .then((result) => result.data)
    .catch((error) => {
      console.error(error.response.data.error);
    });
};

export const getStudyFilters = async (token) => {
  const headers = getHeaders(token);

  return get(`${clientSettings?.Urls.EndPoints.StudyFilters}`, headers)
    .then((result) => result.data)
    .catch((error) => {
      console.error(error.response.data.error);
    });
};

export const getClientSettings = async (token) => {
  const headers = getHeaders(token);
  return get(`${clientSettingsApiUrl}`, headers)
    .then((result) => {
      clientSettings = result.data;
      return result.data;
    })
    .catch((error) => {
      console.error(error.response.data.error);
    });
};

export const postAssessments = async (token, obj, { assessmentExporting }) => {
  const options = postHeaders(token);
  if (assessmentExporting) {
    options.headers["Accept"] = "application/vnd.ms-excel";
    options.responseType = "blob";
  }
  return post(`${clientSettings.Urls.EndPoints.Assessment}`, obj, options);
};

export const loadFile = async (token, studyId) => {
  const headers = getHeaders(token);

  let url = clientSettings.Urls.EndPoints.StudyLoad;
  url = url.replace("{studyId}", encodeURIComponent(studyId));

  return get(`${url}`, headers)
    .then((result) => result.data)
    .catch((error) => {
      console.error(error.response.data.error);
    });
};

export const loadFiles = async (token) => {
  const headers = getHeaders(token);

  return get(`${clientSettings.Urls.EndPoints.StudyList}`, headers)
    .then((result) => result.data)
    .catch((error) => {
      console.error(error.response.data.error);
    });
};

export const translateWinDebutToStudy = async (token, obj, config) => {
  const options = await postHeaders(token);
  if (config !== "auto") {
    options.headers["Configuration-Client"] = config.clientName;
    options.headers["Configuration-Version"] = config.versionNumber;
  } else {
    options.headers["Configuration-Auto"] = true;
  }
  return post(`${clientSettings.Urls.EndPoints.WinDebutToStudy}`, obj, options);
};

export const translateXmlToStudy = async (token, obj) => {
  const options = postHeaders(token);
  options.headers["Configuration-Auto"] = true;
  return post(`${clientSettings.Urls.EndPoints.SmallworldToStudy}`, obj, options);
};

export const saveNetwork = async (token, obj) => {
  const headers = postHeaders(token);
  return post(`${clientSettings.Urls.EndPoints.StudySave}`, obj, headers);
};

export const deleteNetwork = async (token, studyId) => {
  const headers = postHeaders(token);

  let url = clientSettings.Urls.EndPoints.StudyDelete;
  url = url.replace("{studyId}", studyId);

  return deleteAction(url, headers);
};

export const archiveNetwork = async (token, studyId, obj) => {
  const headers = postHeaders(token);

  let url = clientSettings.Urls.EndPoints.StudyArchive;
  url = url.replace("{studyId}", studyId);

  return patch(url, obj, headers);
};

export const updateNetwork = async (token, studyId, obj) => {
  const headers = postHeaders(token);

  let url = clientSettings.Urls.EndPoints.StudyUpdateContent;
  url = url.replace("{studyId}", studyId);

  return patch(url, obj, headers);
};

export const saveFeedback = async (token, obj) => {
  const headers = postHeaders(token);
  return post(`${clientSettings.Urls.EndPoints.FeedbackSave}`, obj, headers)
    .then((result) => result.data)
    .catch((error) => error);
};

export const loadNetworkJson = async (
  token,
  substationNumber,
  feeders,
  mains,
  electricOffice,
  useAntData,
) => {
  const headers = getHeaders(token);
  const feeders_ = feeders.length ? `${feeders.join()}` : "";
  let mains_ = "";
  let electricOffice_ = "";

  if (feeders.length) {
    mains_ = mains ? `${mains}` : "";
  } else {
    mains_ = mains ? `${mains}` : "";
  }

  if (feeders.length || mains.length) {
    electricOffice_ = electricOffice ? `${electricOffice}` : "";
  } else {
    electricOffice_ = electricOffice ? `${electricOffice}` : "";
  }

  let url = clientSettings.Urls.EndPoints.NetworkLoad;
  url = url.replace("{substationNumber}", substationNumber);
  url = url.replace("{feeders}", feeders_);
  url = url.replace("{mainsOnly}", mains_);
  url = url.replace("{electricOfficeOnly}", electricOffice_);
  url = url.replace("{useGeometry}", !(useAntData && clientSettings.Features.DataBasedANTEnabled));

  return get(`${url}`, headers)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const loadFeederList = async (token, substationNumber, useAntData) => {
  const headers = getHeaders(token);

  let url = clientSettings.Urls.EndPoints.NetworkLoadFeeders;
  url = url.replace("{substationNumber}", substationNumber);
  url = url.replace("{useGeometry}", !(useAntData && clientSettings.Features.DataBasedANTEnabled));

  return get(`${url}`, headers)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.error(error);
      let errorText = "There was a problem finding the network.";
      const errorDescription = error?.response?.data?.errors?.id[0];
      if (errorDescription) {
        errorText = errorText + " " + errorDescription;
      }

      return {
        data: {
          network: {
            messages: [
              {
                description: errorText,
                level: 4,
              },
            ],
          },
        },
      };
    });
};

export const getNearbySubstations = async (token, lat, lng, distanceInMeters) => {
  const headers = getHeaders(token);

  let url = clientSettings.Urls.EndPoints.NetworkNearbySubstations;
  url = url.replace("{lat}", lat);
  url = url.replace("{lng}", lng);
  url = url.replace("{distanceInMeters}", distanceInMeters);

  return get(`${url}`, headers)
    .then((result) => {
      return result;
    })
    .catch((error) => console.log(error));
};

export const getLocation = async (token, obj) => {
  const headers = postHeaders(token);
  return post(`${clientSettings.Urls.EndPoints.LocationSearch}`, { Location: obj }, headers)
    .then((result) => {
      return result;
    })
    .catch((error) => error);
};

export const getAutoCompleteLocation = async (token, obj) => {
  const headers = postHeaders(token);
  return post(
    `${clientSettings.Urls.EndPoints.AutoCompleteLocationSearch}`,
    { Location: obj },
    headers,
  )
    .then((result) => {
      return result;
    })
    .catch((error) => error);
};

export const getRagNetwork = async (token, substationNumber) => {
  const headers = getHeaders(token);

  let url = clientSettings.Urls.EndPoints.RAGNetworkLoad;
  url = url.replace("{substationNumber}", substationNumber);

  return get(`${url}`, headers)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const loadCrown = async (token, schemeId, schemeVersion) => {
  const headers = getHeaders(token);

  let url = clientSettings.Urls.EndPoints.CrownLoad;
  url = url.replace("{schemeId}", schemeId);
  url = url.replace("{schemeVersion}", schemeVersion);

  return get(`${url}`, headers)
    .then((result) => result.data)
    .catch((error) => {
      console.error(error.response.data.errors);
    });
};

export const exportCrown = async (token, schemeId, schemeVersion, exportData) => {
  const headers = getHeaders(token);
  let url = clientSettings.Urls.EndPoints.CrownExport;
  url = url.replace("{schemeId}", schemeId);
  url = url.replace("{schemeVersion}", schemeVersion);

  return post(`${url}`, exportData, headers)
    .then((result) => result.data)
    .catch((error) => {
      console.error(error.response.data.errors);
    });
};

export const getBillOfMaterials = async (token, bomData) => {
  const headers = getHeaders(token);
  return post(`${clientSettings.Urls.EndPoints.Calculate}`, bomData, headers)
    .then((result) => result.data)
    .catch((error) => {
      console.error(error?.response?.data?.errors);
      return error?.response?.data;
    });
};

export const loadTransformers = async (token) => {
  const headers = getHeaders(token);
  return get(`${clientSettings?.Urls.EndPoints.TransformerLookUp}`, headers)
    .then((result) => result.data)
    .catch((error) => {
      console.error(error.response.data.error);
    });
};

export const getGeoJson = async (bounds, layer) => {
  const bb = getBounds(bounds);
  const response = await get(
    `${layer.url}&bbox=${bb.southLat},${bb.westLng},${bb.northLat},${bb.eastLng}`,
  );
  const data = await response;
  return data;
};

export const getGeometry = async (token, bounds) => {
  const headers = getHeaders(token);
  const bb = getBounds(bounds);

  const response = await get(
    `${clientSettings.Urls.EndPoints.Layer}?minX=${bb.southLat}&minY=${bb.westLng}&maxX=${bb.northLat}&maxY=${bb.eastLng}`,
    headers,
  );
  const data = await response;
  return data;
};
