// @flow
import { LatLng } from "./simpleTypes";
import { GroupedConnectionPoint } from "../viewModel/groupedConnectionPoint";

export interface ICableProperties {
  geometry: LatLng[];
  startAssetId: string;
  endAssetId: string;
  consumersDownstream: number;
  consumersUpstream: number;
  actualPhasesAvailable: number;
  length: number;
  cableType: string;
  cableTypeIsDefault: Boolean;
  cableTypeOriginal: string;
  cableTypeAssigned: string;
  autoSelect: boolean;
  maximumUtilisationPercent: number;
  maximumCurrentA: number;
  maximumCurrentAPeriod: number;
  earthLoopImpedance: number;
  cableGroup: string;
  cableGroupIsDefault: Boolean;
  numberOfPhases: string;
  numberOfPhasesIsDefault: Boolean;
  numberOfPhasesOriginal: string;
  overrideLength: number;
  groupedConnectionPoints: GroupedConnectionPoint[];
  feederFuseSize: number;
  hasFuseMaxCurrentWarning: Boolean;
  isFirstLeg: boolean;
  hasFuseSize: boolean;
  feederNumber: ?number;
  ducting: boolean;
  ratingType: string;
  AllmaximumUtilisationPercent: number;
  AllmaximumCurrentPhase: number;
  AllmaximumCurrentA: number;
  AllmaximumCurrentAPeriod: number;
  AllminimumCurrentA: number;
  AllmaximumCurrentAPhase1: number;
  AllmaximumCurrentAPhase2: number;
  AllmaximumCurrentAPhase3: number;
  wintermaximumCurrentA: number;
  wintermaximumCurrentAPeriod: number;
  wintermaximumUtilisationPercent: number;
  wintermaximumCurrentPhase: number;
  winterminimumCurrentA: number;
  wintermaximumCurrentAPhase1: number;
  wintermaximumCurrentAPhase2: number;
  wintermaximumCurrentAPhase3: number;
  summermaximumCurrentA: number;
  summermaximumCurrentAPeriod: number;
  summermaximumUtilisationPercent: number;
  summermaximumCurrentPhase: number;
  summerminimumCurrentA: number;
  summermaximumCurrentAPhase1: number;
  summermaximumCurrentAPhase2: number;
  summermaximumCurrentAPhase3: number;
  nearNodeNumber: number;
  farNodeNumber: number;
  fuseSelection: string;
  wpdId: string;
  annotation: string;
  status: string;
  groundTypeOverrides: any[];
  cascadingRAG: string;
  nonCascadingRAG: string;
  wintermaximumServiceCableDemandKVA: number;
  summermaximumServiceCableDemandKVA: number;
  AllmaximumServiceCableDemandKVA: number;
  fusewayStatus: string;
  rootTransformerId: string;
  substationId: string;
  groundTypeOverridesIsDefault: Boolean;
  feederSequence: string;
}

export class CableProperties implements ICableProperties {
  geometry: LatLng[];
  startAssetId: string;
  endAssetId: string;
  consumersDownstream: number;
  consumersUpstream: number;
  actualPhasesAvailable: number;
  length: number;
  cableType: string;
  cableTypeIsDefault: Boolean;
  cableTypeOriginal: string;
  cableTypeAssigned: string;
  autoSelect: boolean;
  maximumUtilisationPercent: number;
  maximumCurrentA: number;
  maximumCurrentAPeriod: number;
  earthLoopImpedance: number;
  cableGroup: string;
  cableGroupIsDefault: Boolean;
  numberOfPhases: string;
  numberOfPhasesIsDefault: Boolean;
  numberOfPhasesOriginal: string;
  styles: any;
  overrideLength: number;
  groupedConnectionPoints: GroupedConnectionPoint[];
  feederFuseSize: number;
  isFirstLeg: boolean;
  hasFuseSize: boolean;
  feederNumber: ?number;
  hasFuseMaxCurrentWarning: Boolean;
  ducting: boolean;
  ratingType: string;
  AllmaximumUtilisationPercent: number;
  AllmaximumCurrentPhase: number;
  AllmaximumCurrentA: number;
  AllmaximumCurrentAPeriod: number;
  AllminimumCurrentA: number;
  AllmaximumCurrentAPhase1: number;
  AllmaximumCurrentAPhase2: number;
  AllmaximumCurrentAPhase3: number;
  wintermaximumCurrentA: number;
  wintermaximumCurrentAPeriod: number;
  wintermaximumUtilisationPercent: number;
  wintermaximumCurrentPhase: number;
  winterminimumCurrentA: number;
  wintermaximumCurrentAPhase1: number;
  wintermaximumCurrentAPhase2: number;
  wintermaximumCurrentAPhase3: number;
  summermaximumCurrentA: number;
  summermaximumCurrentAPeriod: number;
  summermaximumUtilisationPercent: number;
  summermaximumCurrentPhase: number;
  summerminimumCurrentA: number;
  summermaximumCurrentAPhase1: number;
  summermaximumCurrentAPhase2: number;
  summermaximumCurrentAPhase3: number;
  nearNodeNumber: number;
  farNodeNumber: number;
  fuseSelection: string;
  wpdId: string;
  annotation: string;
  status: string;
  groundTypeOverrides: any[];
  cascadingRAG: string;
  nonCascadingRAG: string;
  overrideGroundType: any[];
  wintermaximumServiceCableDemandKVA: number;
  summermaximumServiceCableDemandKVA: number;
  AllmaximumServiceCableDemandKVA: number;
  fusewayStatus: string;
  rootTransformerId: string;
  substationId: string;
  groundTypeOverridesIsDefault: Boolean;
  feederSequence: string;
}
