import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, FormGroup, Label, Input, Row, Col, Button } from "reactstrap";

import { FormContext } from "../context/FormContext";
import { ToolContext } from "../context/ToolContext";
import { formatLength } from "../utils/numberFunctions";

import ConnectionForm from "./ConnectionForm";
import RadioBtnGrp from "./RadioBtnGrp";
import DataAlert from "./DataAlert";
import { networkContainsOverheadLine } from "../utils/networkFunctions";
import * as poleProperties from "../constants/poleProperties";
import * as fusewayStatus from "../constants/fusewayStatus";
import { clearResults, updateTransformer, findTransformer, updateCable } from "../app/networkSlice";
import { getResultProperties } from "../utils/referenceFunctions";
import { TRANSFORMER_PHASES } from "../constants/phase";
import { store } from "../app/store";
import { endBatchAction, startBatchAction } from "../app/undoable";
import DynamicPropertiesList from "../components/DynamicPropertiesList";

const phasesObj = { ...TRANSFORMER_PHASES };

const fuseSelectionObj = {
  name: "fuseSelection",
  obj: [
    { id: "transformerFuseMinimum", value: "Minimum", color: "light" },
    { id: "transformerFuseDefault", value: "Default", color: "light" },
    { id: "transformerFuseManual", value: "Manual", color: "light" },
  ],
};

const TransformerForm = ({ transformer }) => {
  const TRANSFORMERFORM = "TransformerForm";
  const { formState, dispatch } = useContext(FormContext);
  const { clientSettings, reference } = formState;
  const { toolState, setToolState } = useContext(ToolContext);
  const { errors, clickedAsset } = toolState;

  const dispatchRedux = useDispatch();

  const activeTransformer = useSelector((state) => findTransformer(state, clickedAsset?.id));
  const cables = useSelector((state) => state.network.present.cables);

  const {
    nameplateRating,
    nameplateRatingIsDefault,
    mounting,
    numberOfPhases,
    numberOfPhasesIsDefault,
    fuseSelection,
    fuseSelectionIsDefault,
    autoSelect,
    hvFaultLevel,
    hvFaultLevelIsDefault,
    xrRatio,
    annotation,
    xrRatioIsDefault,
    ohEnvironment,
    poleTermLength,
    poleTermLengthIsDefault,
    designVoltage,
  } = clickedAsset ? activeTransformer : transformer;

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, []);

  const hasFeederDefault = cables.some((p) => p.feederFuseSizeIsDefault);

  const defaultsArr = [
    { name: numberOfPhasesIsDefault, label: "numberOfPhases" },
    { name: nameplateRatingIsDefault, label: "nameplateRating" },
    { name: fuseSelectionIsDefault, label: "fuseSelection" },
    { name: hvFaultLevelIsDefault, label: "hvFaultLevel" },
    { name: xrRatioIsDefault, label: "xrRatio" },
  ];

  const changeInputValue = (e) => {
    const name = e.name ? e.name : e.target.name;
    const value = e.value !== undefined ? e.value : e.target.value;
    if (handleDefault) handleDefault(name);

    store.dispatch(startBatchAction());
    try {
      if (name === "poleTermLength") {
        dispatch({
          form: "transformerProperties",
          field: "isDefaultPoleTermLength",
          value: false,
          type: "UPDATE_FIELD",
        });
        if (clickedAsset) {
          dispatchRedux(
            updateTransformer({
              id: clickedAsset.id,
              name: "isDefaultPoleTermLength",
              value: false,
            }),
          );
        }
      }

      dispatch({
        form: "transformerProperties",
        field: name,
        value: value,
        type: "UPDATE_FIELD",
      });
      if (clickedAsset) {
        const filteredErrors = errors.messages
          ? errors.messages.map((message) => message.link)
          : [];

        if (filteredErrors.includes(clickedAsset?.id)) {
          let _errors = { ...errors };
          _errors.messages = _errors.messages
            ? _errors.messages.filter((message) => message.link !== clickedAsset?.id)
            : [];

          const _toolState = toolState;
          _toolState.errors = _errors;
          setToolState(_toolState);
        }
        dispatchRedux(updateTransformer({ id: clickedAsset.id, name, value }));
      }
    } finally {
      store.dispatch(endBatchAction());
    }
  };

  const changeFeederInputValue = (e, asset) => {
    const name = e.name ? e.name : e.target.name;
    const value = e.value !== undefined ? e.value : e.target.value;

    dispatchRedux(updateCable({ id: asset.id, name, value }));
  };

  const setSelectedPhase_ = (e) => {
    hideResults();
    changeInputValue({ name: "numberOfPhases", value: e.value });
  };

  const setSelectedFuseSelection_ = (e) => {
    hideResults();
    store.dispatch(startBatchAction());
    try {
      changeInputValue({ name: "fuseSelection", value: e.value });
      setSelectedFuseSelectionOnFeeders(e);
    } finally {
      store.dispatch(endBatchAction());
    }
  };

  const setSelectedFuseSelectionOnFeeders = (e) => {
    filteredFeeders().forEach((feeder) => {
      changeFeederInputValue({ name: "fuseSelection", value: e.value }, feeder);
    });
  };

  const filteredFeeders = () => {
    return cables.filter(
      (cable) => cable.startAssetId === clickedAsset?.id || cable.endAssetId === clickedAsset?.id,
    );
  };

  const hideResults = () => {
    const _toolState = toolState;
    _toolState.showResults = false;
    setToolState(_toolState);
    dispatchRedux(clearResults(getResultProperties(clientSettings)));
  };
  const handleChange = (e) => {
    if (e.name !== "autoSelect") e.preventDefault();
    hideResults();
    changeInputValue(e);
  };
  const handleChangeWithFormat = (e) => {
    if (e?.value !== undefined) {
      e.value = formatLength(e.value);
    } else if (e?.target?.value !== undefined) {
      e.target.value = formatLength(e.target.value);
    }
    handleChange(e);
  };

  const handleDesignVoltageFormat = (e) => {
    const value = e?.target?.value;
    if (value === "" || value < 0) {
      e.target.value = 0;
      handleChange(e);
    }
  };

  const handleChange2 = (e) => {
    store.dispatch(startBatchAction());
    try {
      handleChange(e);
      const autoSelect = { name: "autoSelect", value: e.target.value === "auto" };
      handleChange(autoSelect);
    } finally {
      store.dispatch(endBatchAction());
    }
  };

  const handleChange3 = (e, feeder) => {
    hideResults();
    changeFeederInputValue(e, feeder);
  };

  // disable any phase options that have no available transformer ratings
  phasesObj.obj.forEach((p) => {
    p.disabled = reference.transformers.types.every(
      (f) => !f[p.value.toLowerCase() + "phase"][mounting],
    );
  });

  const sizeList = reference.transformers.types.filter(
    (f) =>
      (mounting === "gmt" && f[numberOfPhases.toLowerCase() + "phase"].gmt) ||
      (mounting === "pmt" && f[numberOfPhases.toLowerCase() + "phase"].pmt),
  );

  //if previously selected size not in list anymore, set it to Auto
  if (sizeList.filter((f) => f.rating === parseInt(nameplateRating)).length === 0 && !autoSelect) {
    changeInputValue({ name: "autoSelect", value: true });
    changeInputValue({ name: "nameplateRating", value: "auto" });
  }

  const handleDefault = (e) => {
    if (defaultsArr.some((d) => d.label === e)) {
      dispatch({
        form: "transformerProperties",
        field: `${e}IsDefault`,
        value: false,
        type: "UPDATE_FIELD",
      });
      if (clickedAsset) {
        dispatchRedux(
          updateTransformer({ id: clickedAsset.id, name: `${e}IsDefault`, value: false }),
        );
      }
    }
  };
  const handleCableDefault = (cable, e) => {
    if (clickedAsset) {
      dispatchRedux(updateCable({ id: cable.id, name: `${e}IsDefault`, value: false }));
    }
  };

  const handleAllDefaults = () => {
    store.dispatch(startBatchAction());
    try {
      defaultsArr.forEach((d) => {
        if (d.name) handleDefault(d.label);
      });
      if (hasFeederDefault) {
        cables.forEach((cable) => {
          if (cable.feederFuseSizeIsDefault) {
            dispatchRedux(
              updateCable({ id: cable.id, name: "feederFuseSizeIsDefault", value: false }),
            );
          }
        });
      }
    } finally {
      store.dispatch(endBatchAction());
    }
  };

  return (
    <Form
      className="w-100 text-light"
      style={{
        padding: ".75rem",
      }}
    >
      <FormGroup>
        <Input
          id="annotation"
          name="annotation"
          className="w-100"
          placeholder="Enter label/annotation"
          value={annotation}
          onChange={handleChange}
          maxLength="50"
        />
      </FormGroup>
      {(defaultsArr.find((d) => d.name === true) || hasFeederDefault) && (
        <Button color="warning" outline block className="mb-3" onClick={() => handleAllDefaults()}>
          Resolve all default warnings
        </Button>
      )}
      {clickedAsset && (
        <span className="transformerId" style={{ position: "absolute", visibility: "hidden" }}>
          {clickedAsset?.id}
        </span>
      )}

      {clickedAsset && clickedAsset.nodeNumber && (
        <FormGroup>
          <Label className="id">ID: {clickedAsset.nodeNumber}</Label>
        </FormGroup>
      )}

      {clickedAsset && clientSettings.Features.DynamicPropertiesEnabled && (
        <DynamicPropertiesList propertyType={"PowerTransformer"} />
      )}

      <FormGroup>
        <Label for="phases">
          No. of Phases
          {numberOfPhasesIsDefault && (
            <DataAlert updateDefault={() => handleDefault("numberOfPhases")} />
          )}
        </Label>
        <RadioBtnGrp
          items={phasesObj}
          changeValue={setSelectedPhase_}
          selectedValue={numberOfPhases}
        />
      </FormGroup>
      <FormGroup>
        <Label for="nameplateRating">
          Rating (kVA)
          {nameplateRatingIsDefault && (
            <DataAlert updateDefault={() => handleDefault("nameplateRating")} />
          )}
        </Label>
        <Input
          type="select"
          name="nameplateRating"
          id="nameplateRating"
          value={autoSelect ? "auto" : nameplateRating}
          onChange={handleChange2}
        >
          <option value="auto">Auto</option>
          {reference.transformers.types
            .filter(
              (f) =>
                (mounting === "gmt" && f[numberOfPhases.toLowerCase() + "phase"].gmt) ||
                (mounting === "pmt" && f[numberOfPhases.toLowerCase() + "phase"].pmt),
            )
            .map((type) => (
              <option key={type.rating} value={type.rating}>
                {type.rating}
              </option>
            ))}
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="designVoltage">Design Voltage (V)</Label>
        <Input
          type="number"
          id="designVoltage"
          name="designVoltage"
          value={designVoltage}
          onChange={handleChange}
          onBlur={handleDesignVoltageFormat}
          min="0"
        />
      </FormGroup>
      <FormGroup>
        <Label for="fuseSelection">
          Fuse Selection
          {fuseSelectionIsDefault && (
            <DataAlert updateDefault={() => handleDefault("fuseSelection")} />
          )}
        </Label>
        <RadioBtnGrp
          items={fuseSelectionObj}
          changeValue={setSelectedFuseSelection_}
          selectedValue={fuseSelection}
        />
      </FormGroup>
      {fuseSelection === "Manual" ? (
        <>
          {filteredFeeders().map((cable) => (
            <FormGroup>
              <Label for={`feederFuseSize${cable.feederNumber}`}>
                Fuse Size - Feeder: {cable.feederNumber}
                {cable.feederFuseSizeIsDefault && (
                  <DataAlert updateDefault={() => handleCableDefault(cable, "feederFuseSize")} />
                )}
              </Label>
              <Input
                type="select"
                name="feederFuseSize"
                id={`feederFuseSize${cable.feederNumber}`}
                value={cable.feederFuseSize}
                onChange={(e) => handleChange3(e, cable)}
              >
                <option value={null}>No rating</option>
                {reference.fuses.ratings.map((rating) => (
                  <option key={rating} value={rating}>
                    {rating}
                  </option>
                ))}
              </Input>
            </FormGroup>
          ))}
        </>
      ) : (
        ""
      )}
      <FormGroup row>
        <Col sm={6}>
          <Label for="hvFaultLevel">
            HV Fault Level (MVA)
            {hvFaultLevelIsDefault && (
              <DataAlert updateDefault={() => handleDefault("hvFaultLevel")} />
            )}
          </Label>
          <Input
            type="number"
            step="0.01"
            id="hvFaultLevel"
            name="hvFaultLevel"
            value={hvFaultLevel}
            onChange={handleChange}
          />
        </Col>
        <Col sm={6}>
          <Label for="xrRatio">
            X/R Ratio
            {xrRatioIsDefault && <DataAlert updateDefault={() => handleDefault("xrRatio")} />}
          </Label>
          <Input
            type="number"
            step="0.01"
            id="xrRatio"
            name="xrRatio"
            value={xrRatio}
            onChange={handleChange}
          />
        </Col>
      </FormGroup>
      {clientSettings.Features.CostingEnabled && networkContainsOverheadLine(cables) && (
        <FormGroup>
          <Label for="ohEnvironment">Overhead Environment</Label>
          <Input
            type="select"
            name="ohEnvironment"
            id="ohEnvironment"
            value={ohEnvironment}
            onChange={handleChange}
          >
            <option value="normal">Normal</option>
            <option value="severe">Severe</option>
          </Input>
        </FormGroup>
      )}
      {mounting === "pmt" && clientSettings.Features.PoleTermLengthEnabled && (
        <div className="mt-2">
          <Label for={poleProperties.POLETERMLENGTH}>
            Pole Term Length (m)
            {poleTermLengthIsDefault && (
              <DataAlert updateDefault={() => handleDefault(poleProperties.POLETERMLENGTH)} />
            )}
          </Label>
          <Input
            type="number"
            id={poleProperties.POLETERMLENGTH}
            name={poleProperties.POLETERMLENGTH}
            placeholder={formatLength(0)}
            value={poleTermLength}
            onChange={handleChange}
            onBlur={handleChangeWithFormat}
          />
        </div>
      )}
      {clientSettings.Features.ConfigurableLinkBoxEnabled && filteredFeeders().length > 0 && (
        <div
          className="bg-white-alpha"
          style={{
            padding: ".75rem .75rem 0.01rem .75rem",
            margin: ".75rem 0",
          }}
        >
          <h6
            className="text-light"
            style={{
              fontSize: ".75rem",
              textTransform: "uppercase",
              fontWeight: 200,
            }}
          >
            LV Fuseway
          </h6>

          {filteredFeeders().map((cable) => (
            <FormGroup row key={`fusewayStatus${cable.feederNumber}`}>
              <Label for={`fusewayStatus${cable.feederNumber}`} sm={4}>
                Feeder {cable.feederNumber}
              </Label>
              <Col sm={8}>
                <Input
                  type="select"
                  name="fusewayStatus"
                  id={`fusewayStatus${cable.feederNumber}`}
                  value={cable.fusewayStatus}
                  onChange={(e) => handleChange3(e, cable)}
                >
                  <option value={fusewayStatus.OPEN} key={fusewayStatus.OPEN}>
                    Open
                  </option>
                  <option value={fusewayStatus.CLOSED} key={fusewayStatus.CLOSED}>
                    Closed
                  </option>
                </Input>
              </Col>
            </FormGroup>
          ))}
        </div>
      )}
      <Row>
        <Col>
          <Label>Existing Load</Label>
          <ConnectionForm Item={TRANSFORMERFORM} />
        </Col>
      </Row>
    </Form>
  );
};

export default TransformerForm;
