import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, FormGroup, Label, Input, Col, Button } from "reactstrap";
import { updateWelder, findWelder, clearResults } from "../app/networkSlice";

import { FormContext } from "../context/FormContext";
import { ToolContext } from "../context/ToolContext";

import RadioBtnGrp from "./RadioBtnGrp";
import DataAlert from "./DataAlert";
import { getResultProperties } from "../utils/referenceFunctions";
import { store } from "../app/store";
import { endBatchAction, startBatchAction } from "../app/undoable";
import DynamicPropertiesList from "../components/DynamicPropertiesList";

const phasesObj = {
  name: "phases",
  obj: [
    { id: "welderSinglePhase", value: "Single", color: "light" },
    { id: "welderTwoPhase", value: "Two", color: "light" },
    { id: "welderThreePhase", value: "Three", color: "light" },
  ],
};

const WelderForm = ({ welder }) => {
  const { formState, dispatch } = useContext(FormContext);
  const { clientSettings, reference } = formState;
  const { toolState, setToolState } = useContext(ToolContext);
  const dispatchRedux = useDispatch();

  const { errors, clickedAsset } = toolState;

  const activeWelder = useSelector((state) => findWelder(state, clickedAsset?.id));

  const {
    rating,
    ratingIsDefault,
    ratingUnit,
    numberOfPhases,
    numberOfPhasesIsDefault,
    numberOfBursts,
    numberOfBurstsIsDefault,
    numberOfBurstsUnit,
    pointOfWaveControl,
    powerFactor,
    powerFactorIsDefault,
    efficiency,
    efficiencyIsDefault,
    voc,
    vocIsDefault,
    annotation,
  } = clickedAsset ? activeWelder : welder;

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, []);

  const defaultsArr = [
    { name: numberOfPhasesIsDefault, label: "numberOfPhases" },
    { name: ratingIsDefault, label: "rating" },
    { name: vocIsDefault, label: "voc" },
    { name: numberOfBurstsIsDefault, label: "numberOfBursts" },
    { name: powerFactorIsDefault, label: "powerFactor" },
    { name: efficiencyIsDefault, label: "efficiency" },
  ];

  const changeInputValue = (e) => {
    const name = e.name ? e.name : e.target.name;
    const value = e.value !== undefined ? e.value : e.target.value;
    handleDefault(name);

    dispatch({
      form: "welderProperties",
      field: name,
      value: value,
      type: "UPDATE_FIELD",
    });
    if (clickedAsset) {
      const filteredErrors = errors.messages ? errors.messages.map((message) => message.link) : [];

      if (filteredErrors.includes(clickedAsset.id)) {
        let _errors = { ...errors };
        _errors.messages = _errors.messages
          ? _errors.messages.filter((message) => message.link !== clickedAsset.id)
          : [];

        const _toolState = toolState;
        _toolState.errors = _errors;
        setToolState(_toolState);
      }
      dispatchRedux(updateWelder({ id: clickedAsset.id, name, value }));
    }
  };

  const hideResults = () => {
    const _toolState = toolState;
    _toolState.showResults = false;
    setToolState(_toolState);
    dispatchRedux(clearResults(getResultProperties(clientSettings)));
  };

  const handleChange = (e) => {
    hideResults();
    changeInputValue(e);
  };

  const setSelectedPhase_ = (e) => {
    hideResults();
    changeInputValue({ name: "numberOfPhases", value: e.value });
  };

  const handlepointOfWaveControl = () => {
    hideResults();
    changeInputValue({
      name: "pointOfWaveControl",
      value: !pointOfWaveControl,
    });
  };

  const handleDefault = (e) => {
    if (defaultsArr.some((d) => d.label === e)) {
      dispatch({
        form: "welderProperties",
        field: `${e}IsDefault`,
        value: false,
        type: "UPDATE_FIELD",
      });
      if (clickedAsset) {
        dispatchRedux(updateWelder({ id: clickedAsset.id, name: `${e}IsDefault`, value: false }));
      }
    }
  };

  const handleAllDefaults = () => {
    store.dispatch(startBatchAction());
    try {
      defaultsArr.forEach((d) => {
        if (d.name) handleDefault(d.label);
      });
    } finally {
      store.dispatch(endBatchAction());
    }
  };

  return (
    <Form
      className="w-100 text-light"
      style={{
        padding: ".75rem",
      }}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <FormGroup>
        <Input
          id="annotation"
          name="annotation"
          className="w-100"
          placeholder="Enter label/annotation"
          value={annotation}
          onChange={handleChange}
          maxLength="50"
          autoFocus
        />
      </FormGroup>
      {defaultsArr.find((d) => d.name === true) && (
        <Button color="warning" outline block className="mb-3" onClick={() => handleAllDefaults()}>
          Resolve all default warnings
        </Button>
      )}
      {clickedAsset && (
        <span className="welderId" style={{ position: "absolute", visibility: "hidden" }}>
          {clickedAsset.id}
        </span>
      )}

      {clickedAsset && clickedAsset.nodeNumber && (
        <FormGroup>
          <Label className="id">ID: {clickedAsset.nodeNumber}</Label>
        </FormGroup>
      )}

      {clickedAsset && clientSettings.Features.DynamicPropertiesEnabled && (
        <DynamicPropertiesList propertyType={"EnergyConsumer"} />
      )}

      <FormGroup>
        <Label for="phases">
          No. of Phases
          {numberOfPhasesIsDefault && (
            <DataAlert updateDefault={() => handleDefault("numberOfPhases")} />
          )}
        </Label>
        <RadioBtnGrp
          items={phasesObj}
          changeValue={setSelectedPhase_}
          selectedValue={numberOfPhases}
        />
      </FormGroup>

      <FormGroup row>
        <Col sm={6}>
          <Label for="welder">
            {ratingUnit === "A" ? "Maximum Welding Current" : "Rating"}
            {ratingIsDefault && <DataAlert updateDefault={() => handleDefault("rating")} />}
          </Label>
          <Input type="number" id="rating" name="rating" value={rating} onChange={handleChange} />
        </Col>
        <Col sm={6}>
          <Label for="ratingUnit">Unit</Label>
          <Input
            type="select"
            name="ratingUnit"
            id="wratingUnit"
            value={ratingUnit}
            onChange={handleChange}
          >
            <option>kVA</option>
            <option>A</option>
          </Input>
        </Col>
      </FormGroup>

      {ratingUnit === "A" && (
        <FormGroup row>
          <Col sm={6}>
            <Label for="voc">
              Voc (V)
              {vocIsDefault && <DataAlert updateDefault={() => handleDefault("voc")} />}
            </Label>
            <Input
              type="number"
              step="0.1"
              min="0"
              max="10"
              id="voc"
              name="voc"
              value={voc}
              onChange={handleChange}
            />
          </Col>
        </FormGroup>
      )}

      <FormGroup row>
        <Col sm={6}>
          <Label for="numberOfBursts">
            Number of Bursts
            {numberOfBurstsIsDefault && (
              <DataAlert updateDefault={() => handleDefault("numberOfBursts")} />
            )}
          </Label>
          <Input
            type="number"
            id="numberOfBursts"
            name="numberOfBursts"
            value={numberOfBursts}
            onChange={handleChange}
          />
        </Col>
        <Col sm={6}>
          <Label for="numberOfBurstsUnit">Unit</Label>
          <Input
            type="select"
            name="numberOfBurstsUnit"
            id="numberOfBurstsUnit"
            value={numberOfBurstsUnit}
            onChange={handleChange}
          >
            {reference.welders.numberOfBurstsUnits.map((unit) => (
              <option key={unit.value} value={unit.value}>
                {unit.name}
              </option>
            ))}
          </Input>
        </Col>
      </FormGroup>

      <FormGroup>
        <Button
          color="dark"
          block
          onClick={() => handlepointOfWaveControl()}
          className={pointOfWaveControl ? "active" : ""}
        >
          <div className="d-flex justify-content-between align-items-center">
            <span>Has Point of Wave Control</span>
            <i className={`icon-${pointOfWaveControl ? "check" : "cross"}-solid`}></i>
          </div>
        </Button>
      </FormGroup>

      <FormGroup row>
        <Col sm={6}>
          <Label for="powerFactor">
            Power Factor
            {powerFactorIsDefault && (
              <DataAlert updateDefault={() => handleDefault("powerFactor")} />
            )}
          </Label>
          <Input
            type="number"
            step="0.1"
            id="powerFactor"
            name="powerFactor"
            value={powerFactor}
            onChange={handleChange}
          />
        </Col>
        <Col sm={6}>
          <Label for="efficiency">
            Efficiency (%)
            {efficiencyIsDefault && <DataAlert updateDefault={() => handleDefault("efficiency")} />}
          </Label>
          <Input
            type="number"
            step="0.01"
            id="efficiency"
            name="efficiency"
            value={efficiency}
            onChange={handleChange}
          />
        </Col>
      </FormGroup>
    </Form>
  );
};

export default WelderForm;
